<template>
  <TopNav></TopNav>
  <h1>Logaritma Hesaplama</h1>
  <fieldset>
    <legend>Logaritma Hesaplama Aracı</legend>
    <label for="a">Değer: </label> <input type="number" v-model.number="argument" id="a" step="any" class="green">
    <br>
    <label for="b">Taban: </label> <input type="number" v-model.number="base" id="b" step="any" class="blue">
    <br><br>
    ㏒<sub class="blue">{{ base }}</sub> <span class="green">{{ argument }}</span> = <span class="fuchsia">{{ exponent() }}</span><br><br>
    <span class="blue">{{ base }}</span><sup class="fuchsia">{{ exponent() }}</sup> = <span class="green">{{ argument }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Logaritma",
  data() {
    return {
      argument: 100,
      base: 10,
    }
  },
  components: {
    TopNav
  },
  methods: {
    exponent: function () {
      const formatter = new Intl.NumberFormat('tr-TR', {
        maximumFractionDigits: 10
      });
      const exponent = Math.log(this.argument) / Math.log(this.base);
      const exponentNumber = Number(exponent);
      return formatter.format(exponentNumber);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 90px;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.fuchsia {
  color: fuchsia;
}

footer section a {
  margin: 20px;
}
</style>
